<template>
  <div class="d-flex flex-column">
    <div class="d-flex">
      <div class="secondary rounded-lg mt-8" style="height: 24px; width: 8px" />

      <v-card
        class="py-3 py-md-6 px-4 px-md-8 mb-4"
        color="surface"
        width="100%"
        flat
        tile
      >
        <div class="text-h5 font-weight-bold mb-4">
          <span class="saira"> Avatar </span>
        </div>

        <div class="text-body-1 subtext--text mb-6">
          Escolha a imagem do seu avatar.
        </div>

        <v-item-group v-model="selectedAvatar" mandatory>
          <v-container>
            <v-row>
              <v-col
                v-for="(item, index) of avatarList"
                :key="index"
                cols="6"
                md="3"
                xl="2"
              >
                <v-item v-slot="{ active, toggle }">
                  <v-card
                    :color="active ? 'primary' : 'accent'"
                    class="d-flex align-center justify-center"
                    dark
                    @click="toggle"
                  >
                    <v-img :src="item" :aspect-ratio="4 / 4" contain />
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-container>
        </v-item-group>
      </v-card>
    </div>

    <div class="d-flex justify-end">
      <v-btn
        class="rounded-r-0 rounded-l-lg text-none mr-6"
        color="surface"
        width="96"
        to="/home"
        large
      >
        Cancelar
      </v-btn>

      <v-btn
        class="rounded-l-0 rounded-r-lg text-none"
        color="primary"
        width="96"
        large
        @click="submit()"
      >
        Salvar
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { updateUserData } from "@/services/user";

export default {
  name: "AvatarComponent",
  data() {
    return {
      selectedAvatar: 0,
      avatarList: [
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team1.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team2.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team3.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team4.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team5.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team6.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team7.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team8.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team9.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team10.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team11.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team12.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team13.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team14.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team15.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team16.svg",
      ],
    };
  },
  computed: {
    ...mapState(["localUser"]),
  },
  beforeMount() {
    if (!this.localUser || !this.localUser.avatar) return;

    this.selectedAvatar = this.avatarList.indexOf(this.localUser.avatar);
  },
  methods: {
    submit() {
      const payload = { avatar: this.avatarList[this.selectedAvatar] };
      updateUserData(payload).then(() => this.$emit("update"));
    },
  },
};
</script>

<style></style>
